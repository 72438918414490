.footer{
  background-color: $global__primary;
  padding: 30px;
  position: relative;

  &__title {
    @include BellGothic__Black;
    letter-spacing: 0.5px;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  a {
    color: black!important;
  }

  &__right{
   margin-top: 15px;

    .footer__day {

    }




  }


  &__day {
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }

  &__section {
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__copyright {
    font-size: 12px;
    p {
      margin: 0!important;
    }
  }

  &__socialMedia {
    display: flex;
    justify-content: space-between;
    //width: px;

    svg {
      width: 30px;
      margin-left: 15px;
    }
  }

  &__checkerboard {
    position: absolute;
    top: 15px;
    right: 15px;

  }
}

.ouverture__time {
  &--timeContent {
    display: flex;
    align-items: center;
    font-size: 12px;

    p:last-child {
      margin-left: 5px;
    }
  }
}

@media (min-height: 700px) {
  .ouverture__time {
    &--timeContent {
      font-size: 14px;

      p:last-child {
        margin-left: 15px;
      }
    }
  }
}

@media (min-width: 1000px) {
  .footer {
    padding: 3vw 7vw 3vw;
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__right {
      width: 350px;
    }
  }

  .ouverture__time {
    &--timeContent {
      font-size: 15px;
    }
  }
}

@media (min-width: 1500px) {
  .footer {
    padding: 3vw 20vw 3vw;
  }
}

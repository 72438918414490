.wrapper{
  position: relative;
  z-index: 1;
}
.section__division {
  height: 25px;
  width: 100vw;
  margin-top: 15px;
  margin-bottom: 15px;

  &--primary {
    background-color: $global__primary;
  }
  &--secondary {
    background-color: $global__secondary;
  }
}


.title__underline {
  @include Oswald__Medium;
  font-size: 25px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 45px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    background-color: black;
    bottom: -15px;
    width: 150px;
    left: calc(50% - 150px / 2);
  }
}
@media (min-width: 700px) {
  .title__underline {
    font-size: 30px;
  }
}

.input__global {
  border-radius: 0;
  border: 2px solid $global__grey;
  color: $global__grey-text;
  height: 40px;

  &::placeholder {
  }

  &:focus {

    outline: none;
  }
}

.button__global {
  @include Oswald__Medium;
  border:none;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;

  &--black-yellow {
    background-color: black;
    color: $global__primary;

    &[disabled] {
      opacity: 0.5;
      color: $global__primary;
    }
  }

  &--yellow-black {
    background-color: $global__primary;
    color: black;

    &[disabled] {
      opacity: 0.5;
      color: black;
    }
  }

  &--black-white {
    background-color: black;
    color: white;

    &[disabled] {
      opacity: 0.5;
    }
  }

  &--blue-white {
    background-color: $global__secondary;
    color: white;

    &[disabled] {
      opacity: 0.5;
    }
  }

  &--noBck {
    background-color: unset;
  }

  &--border {
    background-color: unset;
    border: 2px solid $global__grey;
    //color: white;

    &[disabled] {
      opacity: 0.5;
    }
  }
}


.global__input {
  border-radius: 0;
  border: 0 solid black;
  border-bottom-width: 2px;
  color: black;
  height: 40px;
  background-color: transparent;
  width: 100%;
  margin-top: 15px;


  &--yellow {
    //background-color: rgb(255 213 2) !important;
    //background-color: rgb(232, 240, 254) !important;
  }

  &--blue {
    //background-color: rgb(232, 240, 254) !important;

  }

  &::placeholder {
    color: black;

    font-style: italic ;
  }

  &:focus {
    color: black;
    outline: none;
  }

  &--textArea {
    height: auto;
    border: 2px solid black;
    padding: 5px;

    &-border-none {
      border: 0 solid black;
      border-top-width: 2px;
    }
  }

  &--mobile {

  }
}

.checkerboard {
  width: 80px;
  height: 60px;

  &__item {
    position: absolute;
    width: 20px;
    height: 20px;

    &--yellow {
      top: 0;
      right: 0;
      background-color: $global__primary;
    }

    &--black {
      top: 0;
      right: 0;
      background-color: black;
    }

    &--green {
      bottom: 0;
      right: 40px;
      background-color: $global__blue_logo;
    }

    &--blue {
      background-color: $global__green_logo;
      bottom: 20px;
      right: 60px;
    }
  }
}

.commitment {
  position: relative;
  @include Oswald__Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__background {
    position: absolute;
    top: -5vh;
    //left: 0;
    background-image: url('../../../assets/images/home/bck.png');
    background-repeat: repeat;
    height: 300px;
    width: 100%;
    z-index: -10;
  }

  &__wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__box {
    background-color: $global__secondary;
    color: $global__tertiary;
    width: 200px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom:10px;

    &--icon {
      fill: $global__tertiary;
      margin-bottom: 20px;
    }
    &--headline {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 3px;
    }
    &--text {
      text-align: center;
    }
  }
}

@media (min-width: 1000px) {
  .commitment {

    &__background {
      top: -5vh;
      margin-left: 7vw;
      margin-right: 7vw;
      width: 80vw;
      height: 500px;
      border-radius: 25% 10%;
    }

    padding: 100px 7vw 100px 7vw;
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(4, 200px);
      align-items: center;
      justify-content: center;
      grid-gap: 30px;
    }

    &__box {

    }
  }
}

@media (min-width: 1500px) {
  .commitment {
    padding: 100px 20vw 100px 20vw;

    &__wrapper {
    }
  }
}

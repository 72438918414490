.jobOfferForm {
  @include Oswald__Medium;
  width: 100%;
  padding: 0 30px 30px;

  &--form {
    background-color: $global__primary;
    padding: 30px;
  }

  &--title {
    @include Noto__Bold;
    font-size: 16px;
    line-height: 19px;

  }

}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (min-width: 1000px) {
  .jobOfferForm {
    padding: 30px;
  }
}


.environmental {
  padding: 30px;
  width: 100vw;

  p {
    &:last-child {
      margin-bottom: 0;
    }
    text-align: center;
  }
}

@media (min-width: 1000px) {
  .environmental {
    padding: 100px 20vw 0px 20vw;
  }
}

.home {
  @include Noto__Medium;
  $informationWeight: 90%;
  $informationHeight: 50%;
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-bottom: 15px;

  &__image {
    width: 90%;
    height: 75%;
    position: relative;


    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      animation-name: animation;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 12s;

      &:nth-child(1) {
        animation-delay: 10s;
      }

      &:nth-child(2) {
        animation-delay: 6s;

      }

      &:nth-child(3) {
        animation-delay: 2s;
      }
    }

    @keyframes animation {
      0% {
        opacity: 1;
      }
      25% {
        opacity: 0;
      }
      75% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &__information {
    width: $informationWeight;
    height: $informationHeight;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    padding: 10px 10px 30px;

    h1 {
      @include BellGothic__Black;
      //font-weight: 700;
      letter-spacing: 2px;
      font-size: 40px;
      text-transform: uppercase;
      color: $global__secondary;
    }

    h2 {
      font-size: 15px;
    }

    button {
      margin-top: 15px;
    }

    &--checkerboard {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }

  &__button {
    margin-top: 30px;
  }

  .home__popover {
    $iconWeight: 40px;
    $iconHeight: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: $iconWeight;
    height: $iconHeight;
    border-radius: 50%;
    background-color: $global__primary;
    position: absolute;

    z-index: 1;

    &--address {
      top: calc(#{$informationHeight} - #{$iconWeight} / 2);
      left: calc(#{$informationWeight} - #{$iconWeight} / 2);
    }

    &--mobile {
      top: calc(#{$informationHeight} - (2 * #{$iconWeight} / 2) - 30px);
      left: calc(#{$informationWeight} - #{$iconWeight} / 2);
    }

    &--schedule {
      top: calc(#{$informationHeight} - (3 * #{$iconWeight} / 2) - 60px);
      left: calc(#{$informationWeight} - #{$iconWeight} / 2);
    }

    &--link {
      left: calc(#{$informationWeight} - #{$iconWeight} / 2);
      bottom: 25px;
    }
  }

  &__popoverContent {
    position: absolute;
    background-color: $global__primary;
    top: 0;
    padding-top: $menu__mobile--height;
    padding-left: 10%;
    width: 90%;

    u {
      cursor: pointer;
    }

    transform: translateY(-100%);
    transition: all 0.3s ease;

    &--title {
      @include Oswald__Medium;
      //height: 100px;
      border-bottom: 2px solid black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 15px;
      margin-bottom: 15px;
    }

    p {
      max-width: 90%;
    }

    a {
      color: black;
      text-decoration: underline;
    }

    &--mobile, &--schedule, &--address {
      &-content {
        p {
          margin: 0;
        }

        a {
          display: block;
          margin-top: 15px;
        }
      }

      &--open {
        display: block;
        //clip-path: inset(0 0 0 0 );
        transform: translateY(0);
        width: 90%;
        height: 75%;
        top: 0;
      }
    }
  }
}

.schedule {
  &__item {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;

    p {
      margin: 0;
    }


    &--timeContent {
      display: flex;
      align-items: center;
      font-size: 12px;

      p:last-child {
        margin-left: 5px;
      }
    }


    &--day {

    }

    &--time {

    }
  }
}


@media (min-height: 700px) {
  .home {
    &__information {
      padding: 50px 30px 30px;

      h2 {
        font-size: 17px;
      }

      button {
        margin-top: 30px;
      }
    }

    &__popoverContent {
      &--title {
        margin-top: 15px;
        height: 50px;
      }
    }
  }

  .schedule {
    &__item {
      &--timeContent {
        font-size: 14px;

        p:last-child {
          margin-left: 15px;
        }

      }
    }
  }
}

@media (min-height: 1000px) and (max-width: 1000px) {
  .home {
    height: 80vh;

    &__information {
      padding: 50px 30px 30px;
    }
  }
}

@media (min-width: 1000px) {
  .home {
    $homeHeight: 85vh;

    $iconWeight: 50px;
    $iconHeight: 50px;

    display: flex;
    height: $homeHeight;

    margin-bottom: 50px;

    &__image {
      width: 50%;
      height: 100%;
      object-fit: cover;
    }

    h1 {
      font-size: 50px;
    }

    h2 {
      font-size: 22px;
      max-width: 80%;
    }

    button {
      width: 250px;
    }

    &__information {
      width: 50%;
      height: 100%;
      position: static;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h2 {
        text-align: center;
      }

      &--checkerboard {
        bottom: -60px;
        right: 40px;
      }
    }

    .home__popover {
      width: $iconWeight;
      height: $iconHeight;
      cursor: pointer;
      transition: all 0.3s ease;

      &--address {
        top: calc(#{$homeHeight} - #{$iconWeight} / 2);
        left: $iconWeight;
      }

      &--mobile {
        top: calc(#{$homeHeight} - #{$iconWeight} / 2);
        left: calc(#{$iconWeight} + #{$iconWeight} + #{$iconWeight} / 2);
      }

      &--schedule {
        top: calc(#{$homeHeight} - #{$iconWeight} / 2);
        left: calc(#{$iconWeight} + #{$iconWeight} + #{$iconWeight} + (#{$iconWeight} / 2) * 2);
      }

      &--link {
        top: calc(#{$homeHeight} - #{$iconWeight} / 2);
        left: calc(100vw - #{$iconWeight} * 2);;
      }
    }


    $popoverContent__width: 500px;

    &__popoverContent {
      padding: 15px 30px 30px;
      width: $popoverContent__width;
      height: 250px;
      position: absolute;
      bottom: 0;
      top: unset;
      transform: translateY(0);
      transition: all 0.3s ease;
      clip-path: inset(100% 100% 0 0);

      &--address {
        left: calc($iconWeight + $iconWeight / 2);
      }

      &--mobile {
        left: calc(($iconWeight + $iconWeight / 2) * 2);
      }

      &--schedule {
        left: calc(($iconWeight + $iconWeight / 2) * 3);
      }

      &--mobile, &--schedule, &--address {
        &--open {
          background-color: #FCDB02;
          clip-path: inset(0 0 0 0);
        }
      }
    }

    .home__popoverContent--Offset--mobile {
      &-schedule {
        left: calc(#{$popoverContent__width} + #{$iconWeight} / 2 + #{$iconWeight} * 3);
      }
    }

    .home__popoverContent--Offset--address {
      &-mobile {
        left: calc(#{$popoverContent__width} + #{$iconWeight} * 2);
      }

      &-schedule {
        left: calc(#{$popoverContent__width} + #{$iconWeight} / 2 + #{$iconWeight} * 3);
      }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //.home__popoverContent--Offset {
    //
    //  &--mobile {
    //    background-color: #1F3A5F;
    //    left: calc(250px + #{$iconWeight} + #{$iconWeight} + #{$iconWeight} + (#{$iconWeight} / 2) * 2);
    //  }
    //
    //  &--schedule {
    //
    //  }
    //}
  }

  .schedule {
    &__item {
      &--timeContent {
        font-size: 15px;

      }
    }
  }
}

@media (min-width: 1200px) {
  .home {
    &__image {
      width: 60%;
    }

    &__information {
      width: 40%;
    }
  }
}

.home__popoverContent--close {
  cursor: pointer;
}

.sentence {
  @include Oswald__Medium;
  height: 250px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  &__title {
    font-size: 20px;
    text-align: center;
  }

  &__legend {
    display: flex;
    align-items: center;

    &--container {
      display: flex;
      flex-direction: column;

      div {
        &:first-of-type {
          margin-top: 15px;
        }

        &:last-of-type {
          margin-bottom: 15px;
        }

        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    &--image {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      border-radius: 50%;
      object-fit: cover;
      background-color: grey;
    }

    &--name {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

@media (min-width: 1000px) {
  .sentence {
    //height: 30vh;
    &__title {
      font-size: 28px;
      max-width: 60%;
    }

    &__legend {

      &--container {
        display: flex;
        flex-direction: row;

        div {
          margin: 15px;
        }
      }

      &--name {
        font-size: 18px;
      }
    }

    &__legend {
      &--image {
        width: 64px;
        height: 64px;
      }
    }
  }
}

@media (min-height: 1000px) {
  .sentence {
    height: 300px;
  }
}

@media (min-height: 1300px) {
  .sentence {
    height: 350px;
  }
}

.merit {
  display: none;
  background-color: $global__secondary;
  color: white;
  fill: white;
  padding: 30px;
  width: 100vw;

  &--action {
    display: none;
  }

  &__item {
    padding: 15px;

    &--icon {
      padding: 15px;
    }

    &--text {
      margin-bottom: 30px;

      h4 {
        @include Oswald__Medium;
        margin-bottom: 30px;
      }

      p {
        @include Roboto__Regular;
      }
    }
  }
}

@media (min-width: 1000px) {
  .merit {
    &--action {
      display: block;
    }

    //padding-top: 25px;
    //padding-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__item {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-top: 50px;
      }

      &:last-child {
        margin-bottom: 50px;
      }

      &--icon {
        padding: 0;
        width: 20%;
        max-width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--text {
        margin-bottom: 30px;
        padding-left: 30px;
        width: 80%;
      }
    }
  }
}


/*
 * vendors
 */
@import 'theme/vendors/reset';
//

@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.css';
@import '@swimlane/ngx-datatable/assets/icons.css';

@import 'ngx-toastr/toastr';
/*
 * Config
 */
@import 'theme/config/disposition';
@import 'theme/config/colors';
@import 'theme/config/fonts';
//


/*
 * Global
 */
@import 'theme/global/optional';
@import 'theme/global/typography';
//


/*
* blocs
*/
@import 'theme/blocs/global';
@import 'theme/blocs/logo';
@import 'theme/blocs/home/menu';
@import 'theme/blocs/home/header';
@import 'theme/blocs/home/home';
@import 'theme/blocs/home/sentence';
@import 'theme/blocs/home/figure';
@import 'theme/blocs/home/commitment';
@import 'theme/blocs/home/flux';
@import 'theme/blocs/home/newsletter';
@import 'theme/blocs/home/merit';
@import 'theme/blocs/home/packages';
@import 'theme/blocs/home/jobsOffer';
@import 'theme/blocs/home/jobOfferEmail';
@import 'theme/blocs/home/contact';
@import 'theme/blocs/home/environmental';
@import 'theme/blocs/home/footer';

@import 'theme/blocs/modal';

@import 'theme/blocs/legals/legals';
//

.toast-success {
  background-color: #1F3A5F;
  font-weight: bold;
}

.grecaptcha-badge {
  visibility: hidden;
}

.data {
  @include Oswald__Medium;
  width: 100vw;
  background-color: $global__primary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    margin-top: 25px;
  }

  &__wrapper {
    margin-bottom: 25px;
  }

  &__box {
    text-align: center;
    margin-top: 30px;

    &--number {
      font-size: 50px;
      line-height: 50px;
    }

    &--legende {
      margin-top: 10px;
    }
  }
}


@media (min-width: 700px) {
  .data {
    //height: 35vh;
    padding: 80px;
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 70%;
    }
  }
}

@media (min-width: 1000px) {
  .data {
    &__wrapper {
      width: 50%;
    }
  }
}

@media (min-width: 1500px) {
  .data {
    &__wrapper {
      width: 30%;
    }
  }
}

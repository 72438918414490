$menu__mobile--height: 50px;

.menu {
  position: fixed;
  background-color: $global__primary;
  width: 100vw;
  top: 0;
  height: $menu__mobile--height;
  padding: 15px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  border-bottom: 2px solid black;

  &--open {
    overflow: hidden;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--burger {
      z-index: 2;
      margin-right: 10px;
    }

    &--logo {
      padding: 5px;
      //width: 60px;
      z-index: 3;
      outline: none;
    }

    &--list {
      width: 100vw;
      height: 100vh;
      background-color: $global__primary;
      position: absolute;
      top: 0;
      left: 100vw;;
      transition: all 0.3s;
      padding: 50px 25px 25px;

      &-item {
        border: 2px solid black;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
          margin-bottom: 25px;
          width: 200px;
        }

      }

      &-display {
        left: 0;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .menu {
    padding: 30px;

    &__content {
      //border: 2px solid black;

      &--burger {
        display: none;
      }

      &--list {
        position: unset;
        padding: 0;
        width: unset;
        // REFACTOR
        height: 42px;
        //
        display: block;

        &-item {
          height: 100%;
          border: 0;

          width: unset;
          flex-direction: row;


          button {
            //border-left: 2px solid black;
            //text-align: center;
            margin: 0 15px 0 0;
            width: unset;
            // REFACTOR
            //

            //padding: 8px 15px;
            &:last-child {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

}


//
//
//.menu__nav--list {
//  display: none;
//  @media only screen and (min-width: 600px) {
//    display: flex;
//    width: 500px;
//    justify-content: space-around;
//    //margin-right: 7vw;
//    //margin-left: 7vw;
//  }
//  @media only screen and (min-width: 600px) {
//    display: flex;
//    width: 500px;
//    justify-content: space-around;
//    //margin-right: 20vw;
//    //margin-left: 20vw;
//  }
//  @media only screen and (max-width: 600px) {
//    position: absolute;
//    z-index: 2;
//    display: block;
//    top: -100vh;
//    width: 100vw;
//    height: 100vh;
//    left: 0;
//    background-color: $global__primary;
//    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
//    &.menu__nav--open {
//      top: 0vh;
//      display: flex;
//      justify-content: center;
//      align-items: center;
//      flex-direction: column;
//
//      a {
//        margin-bottom: 20px;
//      }
//
//    }
//  }
//}
//
//@media only screen and (max-width: 600px) {
//  .menu__nav--open {
//    overflow: hidden;
//  }
//}
//
//.menu__nav--icon span {
//  background-color: black;
//  position: absolute;
//  border-radius: 2px;
//  transition: .3s cubic-bezier(.8, .5, .2, 1.4);
//  width: 100%;
//  height: 4px;
//  transition-duration: 500ms
//}
//
//.menu__nav--icon span:nth-child(1) {
//  top: 0;
//  left: 0;
//}
//
//.menu__nav--icon span:nth-child(2) {
//  top: 13px;
//  left: 0;
//  opacity: 1;
//}
//
//.menu__nav--icon span:nth-child(3) {
//  bottom: 0;
//  left: 0;
//}
//
//.menu__nav--icon:not(.menu__nav--open):hover span:nth-child(1) {
//  transform: rotate(-3deg) scaleY(1.1);
//}
//
//.menu__nav--icon:not(.menu__nav--open):hover span:nth-child(2) {
//  transform: rotate(3deg) scaleY(1.1);
//}
//
//.menu__nav--icon:not(.menu__nav--open):hover span:nth-child(3) {
//  transform: rotate(-4deg) scaleY(1.1);
//}
//
//.menu__nav--icon.menu__nav--open span:nth-child(1) {
//  transform: rotate(45deg);
//  top: 13px;
//}
//
//.menu__nav--icon.menu__nav--open span:nth-child(2) {
//  opacity: 0;
//}
//
//.menu__nav--icon.menu__nav--open span:nth-child(3) {
//  transform: rotate(-45deg);
//  top: 13px;
//}
//
//@media (min-width: 1000px) {
//  .menu {
//    padding-left: 5vw;
//    padding-right: 5vw;
//
//  }
//}
//
//@media (min-width: 1500px) {
//  .menu {
//    padding-left: 15vw;
//    padding-right: 15vw;
//
//  }
//}

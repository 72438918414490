@font-face {
  font-family: "Oswald__Medium";
  src: url('../../assets/fonts/oswald/Oswald-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto__Medium";
  src: url('../../assets/fonts/noto/NotoSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto__Bold";
  src: url('../../assets/fonts/noto/NotoSans-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto__Regular";
  src: url('../../assets/fonts/roboto/Roboto-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BellGothic__Bold";
  src: url('../../assets/fonts/bellGothic/BellGothicStd-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BellGothic__Black";
  src: url('../../assets/fonts/bellGothic/BellGothicStd-Black.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BellGothic__Light";
  src: url('../../assets/fonts/bellGothic/BellGothicStd-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@mixin Oswald__Medium {
  font-family: Oswald__Medium, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin Noto__Medium {
  font-family: Noto__Medium, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


@mixin Noto__Bold {
  font-family: Noto__Bold, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin Roboto__Regular {
  font-family: Roboto__Regular, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin BellGothic__Bold {
  font-family: BellGothic__Bold, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin BellGothic__Black {
  font-family: BellGothic__Black, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@mixin BellGothic__Light {
  font-family: BellGothic__Light, serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drag-scroll-content {
  display: flex!important;
  //justify-content: center;
  //align-items: center;
}

@mixin chevron() {

  display: block;

  width: 25px;
  height: 25px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  border-width: 5px;
}


@keyframes textLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}


.flux {
  background-color: $global__grey;
  @include Oswald__Medium;
  margin-bottom: 30px;
  &__header {
    background-color: $global__secondary;
    color: $global__primary;
    height: 55px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    //animation: fade .5s cubic-bezier(.445,.05,.55,.95) 1s forwards 1;
    //-webkit-animation: fade .5s cubic-bezier(.445,.05,.55,.95) 1s forwards 1;

    div {

      //transform: translate3d(0,0,0);
      //position: absolute;
      //left: 0;
      //top: .8rem;
      white-space: nowrap;
      font-size: .875em;
      text-transform: uppercase;

      span {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  &__contents {
    //background-color: red;
    padding: 30px 30px 0;
    position: relative;
  }

  &__button {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 0;
    border-radius: 50%;
    top: calc(50% - 25px);

    &--left {
      left: 15px;
    }

    &--right {
      top: calc(50% - 25px);
      right: 15px;
    }



    .arrow {
      @include chevron();
      box-shadow: 2px -2px 0 #FFF;
      cursor: pointer;
      transition: box-shadow 0.2s ease;
      &:hover {
        box-shadow: 4px -4px 0 rgba(252,219,2,1);
      }

      &--right {
        transform: rotate(45deg);
      }

      &--left {
        margin: 10px;
        transform: rotate(-135deg);
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    //flex-direction: column;
    //background-color: red;

    height: 300px;
    //position: relative;
    //left: 0;
    //padding: 30px;
    //width: 30px;

    //overflow-x: scroll;
    //overflow-y: hidden;
    //white-space: nowrap;
    //
    //-webkit-overflow-scrolling: touch;
    //
    //&::-webkit-scrollbar {
    //  display: none;
    //}
  }

  &__item {
    background-color: white;
    width: 250px;
    margin-right: 30px;
    padding: 15px;
    margin-top: 15px;
    height: 250px;
    white-space: normal;
    min-width: 250px;
    position: relative;

    &--date {
      color: $global__grey;
      margin: 0;
    }

    &--section {
      margin: 0;
    }

    &--title {
      margin-top: 15px;
      font-size: 18px;
    }

    &--detail {
      background-color: $global__secondary;
      position: absolute;
      bottom: 15px;
      right: 15px;
      padding: 10px;
      color: white!important;
      cursor: pointer;
    }
  }


  &__summary {
    //display: flex;
    padding: 0px 30px 40px;

    &--item {
      cursor: pointer;
      display: inline;
      padding: 5px 15px;

      margin-right: 10px;
      background-color: white;
      //border-radius: 50%;

      transition: all 0.3s;

      &:hover {
        background-color: $global__primary;
      }

      &-selected {
        background-color: $global__secondary;
        color: white;
      }
    }
  }
}


.is-animate {
  animation: textLoop 6s linear infinite;
}


@keyframes marquee {
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(-20%,0,0);
  }
}


@media (min-width: 1000px) {
  .flux {

    &__contents {
      //background-color: rebeccapurple;
      padding: 50px 7vw 0 7vw;
    }

    &__button {
      &--left {
        left: 25px;
      }

      &--right {
        right: 25px;
      }
    }

    &__item {
      background-color: white;
      //width: 30%;
      padding: 15px;
      margin-top: 15px;
      min-height: 250px;
    }

    &__summary {
      padding: 10px 7vw 50px;
    }
  }
}

@media (min-width: 1500px) {
  .flux {
    margin-bottom: 50px;


    &__contents {
      padding: 50px 20vw 0 20vw;
    }

    &__button {
      &--left {
        left: 15vw;
      }

      &--right {
        right: 15vw;
      }
    }

    &__summary {
      padding: 30px 20vw 50px;
    }

  }
}



.legals {
  padding: 20px;

  button {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__title {
    @include Oswald__Medium;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  li {
    list-style: inside;
  }
}

@media (min-width: 1000px) {
  .legals {
    padding-left: 25%;
    padding-right: 25%;

    p {

    }
  }
}

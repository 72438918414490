.logo {
  display: flex;
  align-items: center;

  svg {
    width: 60px;

  }

  &__text {
    display: none;
  }
}

@media (min-width: 1000px) {
  .logo {

    &__text {
      color: #0078c3;
      display: block;
      text-transform: uppercase;
      @include BellGothic__Bold;
      letter-spacing: 1px;
      margin: 0 0 0 10px;

      text-align: center;


      &--title {
        @include BellGothic__Black;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3px;
        font-size: 15px;
      }

      &--text {
        font-size: 8px;
      }

      p {
        margin: 0;
      }
    }
  }
}

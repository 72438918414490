.newsletter {
  @include Oswald__Medium;
  background-color: $global__primary;
  padding: 30px;
  margin-bottom: 30px;

  &__title {
    margin-bottom: 25px;
  }

  &__information {
    p {
      max-width: 80%;
    }
  }

  h4 {
    @include Oswald__Medium;
  }

  p {
    @include Roboto__Regular;
  }

  &__form {
  }

  &__article {
    width: 100%;
    display: flex;

    .newsletter__input {
      width: 80%;
    }
  }

  &__button {

  }
}

@media (min-width: 1000px) {
  .newsletter {
    display: flex;
    justify-content: center;
    flex-direction: row;

    padding: 100px $disposition__margin--right 100px $disposition__margin--left;

    &__information {
      height: 50%;
      width: 40%;
    }

    &__form {
      height: 50%;
      width: 40%;
    }
  }
}

@media (min-width: 1500px) {
  .newsletter {
    &__information {
      width: 30%;
    }

    &__form {
      width: 30%;
    }
  }
}

.contact {
  @include Oswald__Medium;
  margin-top: 10vw;
  margin-bottom: 10vw;

  &__open {
    cursor: pointer;
    background-color: $global__secondary;
    text-align: center;
    color: white;
    padding: 15px;
  }
}

.contactForm {
  background-color: $global__grey;
  padding: 30px;

  &__article {

    &--upload {
      margin-top: 15px;
    }
  }
}


//.contactForm__input {
//  border-radius: 0;
//  border: 0 solid black;
//  border-top-width: 2px;
//  color: black;
//  height: 40px;
//  background-color: $global__grey;
//  width: 100%;
//  margin-top: 15px;
//
//  &::placeholder {
//    color: black;
//  }
//
//  &:focus {
//    color: black;
//    outline: none;
//  }
//
//  &--textArea {
//    height: auto;
//    border: 2px solid black;
//  }
//}

@media (min-width: 1000px) {
  .contact {
    margin-left: 3vw;
    margin-right: 3vw;
  }

  .contactForm {
    display: flex;
    justify-content: space-between;
  }

  .contactForm__section {
    width: 47%;
  }
}


@media (min-width: 1500px) {
  .contact {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .contactForm {
    display: flex;
    justify-content: space-between;
  }

  .contactForm__section {
    width: 47%;
  }
}

.jobsOffer {
  &__offers {
    padding: 30px;
  }

  &__item {
    width: 100%;
  }

  &__jobTitle {
    @include Oswald__Medium;
    cursor: pointer;
    //height: 45px;
    margin-bottom: 20px;
    border-bottom: 3px solid black;

    display: flex;
    justify-content: space-between;
    &--icon {
      width: 0;
      height: 0;
      margin: 10px;
      border: 0 solid transparent;
      border-right-width: 10px;
      border-left-width: 10px;
      border-bottom: 15px solid $global__grey;

      transition: all 0.3s;
      &-open {
        transform: rotate(180deg);
      }
    }
  }

  &__jobInformation {
    @include Noto__Medium;
    transition: all 4s;

    &--content {
      background-color: $global__grey;
      padding: 15px;
    }
    &--title {
      @include Noto__Bold;
      font-size: 16px;
      line-height: 19px;
    }

    &--subtitle {
      @include Noto__Bold;
      margin-bottom: 5px;
    }

    &--text {

    }

    &--button {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
}

@media (min-width: 1000px) {
  .jobsOffer {
    margin-top: 100px;
    margin-left: 6vw;
    margin-right: 6vw;

    &__title {
      margin-top: 30px;
    }

    &__content {
      display: flex;
      justify-content: space-between;
    }

    &__offers {
      width: 50%;
    }

    &__form {
      width: 50%;
      min-height: 100%;
    }
  }
}




@media (min-width: 1500px) {
  .jobsOffer {
    margin-left: 19vw;
    margin-right: 19vw;
  }
}

.package__button {
  justify-content: space-evenly;
  position: absolute;
  bottom: -50px;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 1;
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50%;

  display: flex;
  align-items: center;

  &--closed {
    display: none;
  }
}

.bouboule {
  width: 8px;
  height: 8px;
  background-color: black;
  left: -20px;
  top: 5px;
  border-radius: 50%;
}

.packages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__box {
    @include Roboto__Regular;
    background-color: $global__primary;
    width: 300px;
    margin: 15px;
    position: relative;

    margin-bottom: 40px;

    &--intro {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 25px;
      height: 200px;


      &--open {
        margin-bottom: 0;
      }

      text-align: center;
      position: relative;

      h4 {
        @include Oswald__Medium;
      }

      p {
        text-align: center;
      }
    }

    &--button {
      position: absolute;
      bottom: 0;
    }

    &--content {
      max-height: 0;
      height: 100%;
      overflow: hidden;

      margin: 0;
      background-color: $global__grey;

      padding: 0 30px 0 50px;
      transition: max-height 0.3s ease-out;

      &--open {
        max-height: 100vh;

        padding-top: 30px;
        padding-bottom: 30px;
      }

      li {
        margin-bottom: 10px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background-color: black;
          left: -20px;
          top: 5px;
          border-radius: 50%;
        }
      }
    }


    &--footer {
      display: none;

      &--open {
        display: block;
        margin-bottom: 40px;
      }

      width: 100%;
      height: 50px;
      background-color: $global__primary;
    }
  }
}

.packages__box--last {
  margin-bottom: 0;

  .packages__box--footer {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1000px) {
  .packages {
    padding: 100px 7vw 0 7vw;

    &__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      margin-top: 30px;

      flex-wrap: wrap;
    }

    &__box {
      margin: 0 0 15px;

      &--content {
        height: 350px;

        max-height: 100vh;

        padding-top: 30px;
        padding-bottom: 30px;
      }

      &--footer {
        display: block;
        margin-bottom: 40px;

        width: 100%;
        height: 50px;
        background-color: $global__primary;
      }
    }
  }

  .package__button {
    display: none;
  }
}

@media (min-width: 1500px) {
  .packages {
    padding: 100px 20vw 0 20vw;

    &__box {
      &--content {
        height: 350px;
      }
    }
  }
}
